import Swiper from 'swiper/bundle';
import { Mousewheel } from 'swiper/modules';
export default class Carousel {
  constructor(element) {
    this.element = element;

    this.init();
  }

  init() {
    this.setOptions();
    new Swiper(this.element, this.options);
  }

  setOptions() {
    if ('normaleAllo' in this.element.dataset) {
      this.options = {
        slidesPerView: 1,
        spaceBetween: 20,
        mousewheel: true,
        mousewheel: {
          releaseOnEdges: true,
          mousewheelSensitivity: 0.5,
        },

        pagination: {
          el: this.element.querySelector('.swiper-pagination'),
        },
      };
    }
  }
}
